/* Dashboard.css */

.dashboard-container {
    min-height: 100vh;
    padding-top: 20px;
}

.dashboard-title {
    color: #e74c3c;
}

.card {
    border: none;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.card-icon {
    font-size: 2rem;
}
